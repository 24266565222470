'use client'

import { Footer, Header } from 'components/organism'

export default function BaseLayout({ children }) {
  return (
    <>
      <Header />
      <main style={{ backgroundColor: 'initial' }}>{children}</main>
      <Footer />
    </>
  )
}
